<template>
  <div>
    <v-divider />
    <v-data-table
      :search="search"
      :loading="loading"
      :headers="headers"
      :items="externals"
      sort-by="name"
      class="elevation-1 mt-3"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details
          >
          </v-text-field>
        </v-toolbar>
        <v-dialog v-model="dialogDelete" max-width="300px">
          <v-card>
            <v-card-title class="headline">هل أنت متأكد من الحذف؟</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text-color="white"
                text
                @click="closeDelete"
                >إلغاء</v-btn
              >
              <v-btn
                color="gray"
                text-color="white"
                text
                @click="deleteItemConfirm"
                >موافق</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="green"
          text-color="white"
          small
          class="mr-2"
          @click="showExternal(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          color="blue"
          v-if="role == 'Admin'"
          text-color="white"
          small
          class="mr-2"
          @click="
            $router.push({
              name: type == 'external' ? 'edit-external' : 'edit-project',
              params: {
                external: item,
                externalId:
                  type == 'external' ? item.idexternal : item.idproject
              }
            })
          "
        >
          mdi-pencil
        </v-icon>
        <v-icon
          color="red"
          v-if="role == 'Admin'"
          text-color="white"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        لا توجد بيانات
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["externals", "type"],
  computed: {
    headers() {
      if (this.type == "transporter") {
        return [
          {
            text: "اسم الجهة الخارجية",
            align: "center",
            sortable: true,
            value: "name"
          },
          {
            text: "المالك",
            align: "center",
            sortable: true,
            value: "owner"
          },
          {
            text: "السيارات",
            align: "center",
            sortable: true,
            value: "cars"
          },
          {
            text: "الموبايل",
            align: "center",
            sortable: false,
            value: "mobile"
          },

          { text: "خيارات", align: "center", value: "actions", sortable: false }
        ];
      } else {
        return [
          {
            text: "اسم الجهة الخارجية",
            align: "center",
            sortable: true,
            value: "name"
          },
          {
            text: "الموقع",
            align: "center",
            sortable: true,
            value: "location"
          },
          {
            text: "المالك",
            align: "center",
            sortable: true,
            value: "owner"
          },
          {
            text: "الإيميل",
            align: "center",
            sortable: false,
            value: "email"
          },
          {
            text: "طبيعة النشاط",
            align: "center",
            sortable: false,
            value: "activityType"
          },
          { text: "خيارات", align: "center", value: "actions", sortable: false }
        ];
      }
    }
  },
  data() {
    return {
      search: "",
      dialogDelete: false,
      loading: false,
      dialog: false,
      page: 1,
      totalItems: 0,
      selectedExternalType: {
        value: 0
      },
      deletedItem: ""
    };
  },
  methods: {
    showExternal(item) {
      if (this.type == "external") {
        this.$router.push({
          name: "show-external",
          params: { externalId: item.idexternal }
        });
      } else if (this.type == "project") {
        this.$router.push({
          name: "show-project",
          params: { externalId: item.idproject }
        });
      } else {
        this.$router.push({
          name: "show-transporter",
          params: { externalId: item.idtransporter }
        });
      }
    },
    deleteItem(item) {
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      let url = null;
      let id = null;
      if (this.type == "external") {
        url = "External/deletexternal?id=";
        id = this.deletedItem.idexternal;
      }
      if (this.type == "project") {
        url = "external/deleteproject?id=";
        id = this.deletedItem.idproject;
      }
      if (this.type == "transporter") {
        url = "Transporters/deletetransporter?id=";
        id = this.deletedItem.idtransporter;
      }
      await this.ApiService.delete(url + id)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.$emit("reload");
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.deletedItem = null;
      this.dialogDelete = false;
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
  }
};
</script>
