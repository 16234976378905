<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" fixed-tabs height="47" @change="getAllExternals">
        <v-tab :href="'#tab-1'">
          <b>المصانع</b>
        </v-tab>
        <v-tab :href="'#tab-2'">
          <b>المشاريع</b>
        </v-tab>
        <v-tab :href="'#tab-3'">
          <b>المقاولين</b>
        </v-tab>
        <v-tab :href="'#tab-4'">
          <b>المباسط</b>
        </v-tab>
        <v-tab :href="'#tab-5'">
          <b>المواقف</b>
        </v-tab>
        <v-tab :href="'#tab-6'">
          <b>الناقلين</b>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-1'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <external-table
                  @reload="getAllExternals"
                  :type="'external'"
                  :externals="externals"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item :value="'tab-2'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <external-table
                  @reload="getAllExternals"
                  :type="'project'"
                  :externals="externals"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item :value="'tab-3'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <external-table
                  @reload="getAllExternals"
                  :type="'external'"
                  :externals="externals"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item :value="'tab-4'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <external-table
                  @reload="getAllExternals"
                  :type="'external'"
                  :externals="externals"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item :value="'tab-5'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <external-table
                  @reload="getAllExternals"
                  :type="'external'"
                  :externals="externals"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item :value="'tab-6'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <external-table
                  @reload="getAllExternals"
                  :type="'transporter'"
                  :externals="externals"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ExternalTable from "./ExternalTable.vue";
export default {
  components: {
    "external-table": ExternalTable
  },
  data() {
    return {
      tab: "tab-1",
      externals: []
    };
  },
  methods: {
    async getAllExternals() {
      let url = null;
      if (this.tab == "tab-1") {
        url = "external/getallexternals?id=1";
        this.externalType = "المصانع";
      }
      if (this.tab == "tab-2") {
        url = "External/getallproject?id=2";
        this.externalType = "المشاريع";
      }
      if (this.tab == "tab-3") {
        url = "external/getallexternals?id=3";
        this.externalType = "المقاولين";
      }
      if (this.tab == "tab-4") {
        url = "external/getallexternals?id=4";
        this.externalType = "المباسط";
      }
      if (this.tab == "tab-5") {
        url = "External/getallexternals?id=5";
        this.externalType = "المواقف";
      }
      if (this.tab == "tab-6") {
        url = "Transporters/getalltransporters";
        this.externalType = "الناقلين";
      }
      this.externals = [];
      await this.ApiService.get(url)
        .then(res => {
          this.externals = res.data.responseData;
        })
        .catch(() => {});
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getAllExternals();
  }
};
</script>
<style scoped>
b {
  font-size: 14px;
}
</style>
